import Axios from "axios";

class LogHelper {
  static sendLogToGoogleChat(text, status_code) {
    try {
      Axios.post("/api/sendTochat", {message: text, site: "A9", status: status_code});
    } catch (error) {
      consoloe.log(error);
    }
  }

  static registerToFrontLogs(text, url) {
    try {
      Axios.post("/api/frontRegisterLog", {message: text, page: url});
    } catch (error) {
      consoloe.log(error);
    }
  }
}

export default LogHelper;
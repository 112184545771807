<template>
  <div>
    <form @submit.prevent="submitForm" class="ui-form" method="POST">
      <div class="row signup__columns mt-md-4 mt-0" v-if="!address_turn">
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="">
          <div class="signup__input-wrapper">
            <input type="text" id="name" v-model="form.name" maxlength="255" required autocomplete="off" />
            <label for="name"> Nome Completo* </label>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="">
          <div class="signup__input-wrapper">
            <the-mask
              aria-label="Telefone"
              :mask="['(##) # ####-####', '(##) ####-#### ']"
              :masked="false"
              type="text"
              id="phone"
              name="phone"
              v-model="form.phone"
              required
              autocomplete="off"
            />
            <label for="phone"> Telefone </label>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="">
          <div class="signup__input-wrapper">
            <input type="email" id="email" @blur="checkEmail($event)" v-model="form.email" maxlength="255" required autocomplete="off" />
            <label for="email"> E-mail* </label>
          </div>
        </div>
        <div class="col-xl-6 d-xl-block d-lg-none col-md-6 col-12">
          <div class="signup__input-wrapper">
            <select id="education" v-model="form.education" required autocomplete="off">
              <option value="1">Fundamental</option>
              <option value="2">Ensino Médio</option>
              <option value="3">Graduando</option>
              <option value="4">Graduado</option>
              <option value="5">Pós-Graduando</option>
              <option value="6">Pós-Graduado</option>
            </select>
            <label for="education"> Escolaridade* </label>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="">
          <div class="signup__input-wrapper">
            <input type="password" id="password" v-model="form.password" minlength="6" maxlength="20" required autocomplete="off" />
            <label for="password"> Senha* </label>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="">
          <div class="signup__input-wrapper">
            <input type="password" id="confirms" v-model="form.password_confirmation" minlength="6" maxlength="20" required autocomplete="off" />
            <label for="confirms"> Confirmar Senha* </label>
          </div>
        </div>
        <!-- <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="" v-if="form.person_type == 'pf'">
          <div class="signup__input-wrapper">
            <the-mask aria-label="CPF" name="cpf" id="cpf" :masked="true" :mask="['###.###.###-##']" v-model="form.cpf" autocomplete="off" />
            <label for="cpf"> CPF </label>
            <p class="signup__helper" v-if="!form.cpf && is_app">Não obrigatório</p>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="" v-if="form.person_type == 'pf'">
          <div class="signup__input-wrapper">
            <the-mask
              aria-label="CPF"
              name="birthday"
              id="birthday"
              :masked="true"
              :mask="['##/##/####']"
              v-model="form.birthday"
              autocomplete="off"
            />
            <label for="birthday"> Data de nascimento </label>
            <p class="signup__helper" v-if="!form.cpf && is_app">Não obrigatório</p>
          </div>
        </div> -->
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="" v-if="form.person_type == 'pj'">
          <div class="signup__input-wrapper">
            <input type="text" id="social-name" v-model="form.social_name" required autocomplete="off" />
            <label for="social-name"> Razão Social* </label>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12 d-flex flex-column" data-wronged="" v-if="form.person_type == 'pj'">
          <div class="signup__input-wrapper">
            <input type="text" id="state-registration" v-model="form.state_registration" autocomplete="off" />
            <label for="state-registration"> Inscrição Estadual </label>
          </div>
          <div class="align-items-center d-flex mt-2">
            <input id="state-registration-checkbox" type="checkbox" v-model="form.state_registration_checkbox" />
            <label class="mb-0 ml-2" for="state-registration-checkbox">Isento</label>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="" v-if="form.person_type == 'pj'">
          <div class="signup__input-wrapper">
            <the-mask
              aria-label="CNPJ"
              name="cnpj"
              id="cnpj"
              :masked="true"
              :mask="['##.###.###/####-##']"
              v-model="form.cnpj"
              required
              autocomplete="off"
            />
            <label for="cnpj"> CNPJ* </label>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="" v-if="form.person_type == 'pj'">
          <div class="signup__input-wrapper">
            <input type="text" id="responsible" v-model="form.responsible" required autocomplete="off" />
            <label for="responsible"> Responsável* </label>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="" v-if="form.person_type == 'pj'">
          <div class="signup__input-wrapper">
            <input type="text" id="position" v-model="form.position" required autocomplete="off" />
            <label for="position"> Cargo* </label>
          </div>
        </div>
      </div>
      <div class="row signup__columns mt-md-4 mt-0" v-if="address_turn">
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="">
          <div class="signup__input-wrapper">
            <the-mask aria-label="CEP" id="cep" :masked="true" :mask="['#####-###']" v-model="address.cep" @input="viaCep()" autocomplete="off" />
            <label for="cep"> CEP </label>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="">
          <div class="signup__input-wrapper">
            <input type="text" id="street" v-model="address.street" maxlength="500" autocomplete="off" />
            <label for="street"> Rua </label>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="">
          <div class="signup__input-wrapper">
            <input type="text" id="neighbourhood" v-model="address.neighbourhood" maxlength="255" autocomplete="off" />
            <label for="neibourhood"> Bairro </label>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="">
          <div class="signup__input-wrapper">
            <input type="number" id="number" v-model="address.number" maxlength="20" autocomplete="off" />
            <label for="number"> Número </label>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="">
          <div class="signup__input-wrapper">
            <input type="text" id="complement" v-model="address.complement" maxlength="255" autocomplete="off" />
            <label for="complement"> Complemento </label>
          </div>
        </div>
        <div class="col-xl-6 d-xl-block d-none"></div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="">
          <div class="signup__input-wrapper">
            <select id="country" v-model="address.country">
              <option disabled selected>Por favor, selecione um estado</option>
              <option v-for="(item, index) in countries" :key="index" :value="item.nome_pais">
                {{ item.nome_pais }}
              </option>
            </select>
            <label for="country"> País </label>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="">
          <div class="signup__input-wrapper">
            <select id="state" v-model="address.state" v-on:change="changesState()" v-if="address.country == 'Brasil'">
              <option disabled selected>Por favor, selecione um estado</option>
              <option v-for="(item, index) in locations.estados" :key="index" :value="item.nome">
                {{ item.nome }}
              </option>
            </select>
            <input id="state" type="text" v-model="address.state" v-else />
            <label for="state"> Estado </label>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-6 col-12" data-wronged="">
          <div class="signup__input-wrapper">
            <select id="city" v-model="address.city" v-if="address.country == 'Brasil'">
              <option disabled selected>Por favor, selecione uma cidade</option>
              <option v-for="(item, index) in cities" :key="index" :value="item">
                {{ item }}
              </option>
            </select>
            <input id="city" type="text" v-model="address.city" v-else />
            <label for="city"> Cidade </label>
          </div>
        </div>
        <div class="col-12">
          <div class="signup__check">
            <input type="checkbox" v-model="form.check1" id="check1" />
            <label for="check1"
              >Autorizo a coleta de meus dados pessoais solicitados no momento em que realizo meu cadastro no Evento. Esses dados são importantes para
              evitarmos eventuais fraudes e para conseguirmos entregar uma melhor experiência ao participante. Para mais informações acesse nossa
              política de privacidade.</label
            >
          </div>
        </div>
        <div class="col-12">
          <div class="signup__check">
            <input type="checkbox" v-model="form.check2" id="check2" />
            <label for="check2"
              >Autorizo o uso e tratamento de minha imagem que poderá ser divulgada em redes sociais e plataformas de streaming por um período de até
              5 anos. Para mais informações acesse nossa política de privacidade.</label
            >
          </div>
        </div>
        <div class="col-12">
          <div class="signup__check">
            <input type="checkbox" v-model="form.check3" id="check3" />
            <label for="check3"
              >Autorizo receber conteúdos de parceiros e patrocinadores do Evento antes, durante e após a Desbravalley. Para mais informações acesse
              nossa política de privacidade.</label
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12 d-flex flex-column align-items-center">
          <button type="submit" class="ui-button--blue signup__submit" :disabled="block == true">
            {{ button }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import locations from "./locations/city.json";
import LogHelper from "./Helpers/LogHelper";
import countries from "../front/locations/countries.json";
import dial_codes from "../front/locations/dial_codes.json";
export default {
  props: ["register_endpoint", "check_email_endpoint", "login_endpoint", "home_endpoint", "is_app"],
  data() {
    return {
      countries: countries,
      dial_codes: dial_codes,
      form: {
        name: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
        person_type: "pf",
        // For PF
        cpf: "",
        birthday: "",
        // For PJ
        social_name: "",
        state_registration: "Isento",
        state_registration_checkbox: true,
        cnpj: "",
        responsible: "",
        position: "",
        check1: 0,
        check2: 0,
        check3: 0,
      },
      address: {
        cep: "",
        street: "",
        neighbourhood: "",
        number: "",
        complement: "",
        dial_code: "",
        state: "",
        city: "",
        country: "",
      },
      button: "Cadastrar-se",
      disabled: false,
      address_turn: false,
      locations: locations,
      cities: "",
      block: false,
      is_cep_ok: true,
      is_email_ok: true,
    };
  },
  watch: {
    "form.person_type": function () {
      this.removeErrorMessages();
    },
    "form.state_registration_checkbox": function () {
      if (this.form.person_type !== "pf") {
        if (this.form.state_registration_checkbox) {
          this.form.state_registration = "Isento";
        }
        if (!this.form.state_registration_checkbox && this.form.state_registration === "Isento") {
          this.form.state_registration = "";
        }
      }
    },
    "form.state_registration": function () {
      if (this.form.person_type !== "pf") {
        if (this.form.state_registration !== "Isento") {
          this.form.state_registration_checkbox = false;
        } else {
          this.form.state_registration_checkbox = true;
        }
      }
    },
  },
  methods: {
    changePersonType(event) {
      event.preventDefault();
      var person = event.composedPath()[0].value == "pf" ? "Pessoa Física" : "Pessoa Jurídica";
      if (person != (this.form.person_type == "pf" ? "Pessoa Física" : "Pessoa Jurídica")) {
        this.$swal({
          title: "Troca de Pessoa",
          text: "Trocando para " + person + " você irá perder todos os dados que preencheu até agora!",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Trocar para " + person,
          cancelButtonColor: "#DF1949",
          confirmButtonColor: "#8B8B8B",
        }).then((confirm) => {
          if (confirm.value) {
            this.form.person_type = this.form.person_type == "pf" ? "pj" : "pf";
            this.resetForm();
          }
        });
      }
    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
        // For PF
        cpf: "",
        person_type: this.form.person_type,
        birthday: "",
        // For PJ
        social_name: "",
        state_registration: "Isento",
        state_registration_checkbox: true,
        cnpj: "",
        responsible: "",
        position: "",
        check1: 0,
        check2: 0,
        check3: 0,
      };

      this.address = {
        cep: "",
        street: "",
        neighbourhood: "",
        number: "",
        dial_code: "",
        complement: "",
        state: "",
        city: "",
        endereco: "",
        country: "",
      };
    },
    removeErrorMessages() {
      //Removes all error messages from the inputs
      var items = document.getElementsByClassName("ui-wronged");
      for (var i = items.length - 1; i >= 0; i--) {
        if (items[items.length - 1].children[0].id == "email") {
          continue;
        }
        items[items.length - 1].classList.remove("ui-wronged");
      }
    },
    async checkEmail(email) {
      document.getElementsByClassName("signup__submit")[0].disabled = true;
      if (this.form.email) {
        await axios
          .post(this.check_email_endpoint, { email: this.form.email })
          .then((response) => {
            if (response.data.name) {
              document.getElementById("email").parentElement.classList.remove("ui-correct");
              this.assignError(document.getElementById("email"), "Já há uma conta com este e-mail!");
              this.is_email_ok = false;
              document.getElementsByClassName("signup__submit")[0].removeAttribute("disabled");
            } else if (response.data == "") {
              document.getElementById("email").parentElement.classList.remove("ui-wronged");
              this.assignSuccess(document.getElementById("email"), "Este e-mail é válido :)");
              this.is_email_ok = true;
              document.getElementsByClassName("signup__submit")[0].removeAttribute("disabled");
            }
          })
          .catch((e) => {
            let message = "";
            document.getElementById("email").parentElement.classList.remove("ui-correct");
            if (e.response.data.errors.email[0] == "validation.unique") {
              message = "Já há uma conta com este e-mail!";
            } else if (e.response.data.errors.email[0] == "validation.required") {
              message = "É necessário preencher um e-mail!";
            } else if (e.response.data.errors.email[0] == "validation.email" || e.response.data.errors.email[0] == "validation.regex") {
              message = "Preencha com um e-mail válido!";
            }
            let item = document.getElementById("email");
            this.assignError(item, message);
            document.getElementsByClassName("signup__submit")[0].removeAttribute("disabled");
            LogHelper.sendLogToGoogleChat(`Formulário de cadastro | ${message}`, 406);
            this.is_email_ok = false;
          });
      } else {
        document.getElementsByClassName("signup__submit")[0].removeAttribute("disabled");
      }
    },
    async submitForm() {
      if (this.form.check1 == true) {
        this.form.check1 = 1;
      }
      if (this.form.check2 == true) {
        this.form.check2 = 1;
      }
      if (this.form.check3 == true) {
        this.form.check3 = 1;
      }

      document.getElementsByClassName("signup__submit")[0].disabled = true;
      let is_form_ok = await this.isFormOk();
      if (is_form_ok && !this.address_turn && this.is_email_ok) {
        document.getElementById("email").parentElement.classList.remove("ui-correct");
        document.getElementsByClassName("signup__submit")[0].disabled = true;
        this.removeErrorMessages();
        const sleepingPromise = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
        const sleep = async () => {
          await sleepingPromise(1000);
          if (!this.is_app) {
            this.address_turn = true;
          } else {
            this.dial_codes.map((country) => {
              if (this.address.dial_code == country.name) {
                this.address.whatsapp_code = country.dial_code;
                this.address.country_code = country.code;
              }
            });
            axios
              .post(this.register_endpoint, {
                client: this.form,
                address: this.address,
              })
              .then((response) => {
                if (response.status == 201) {
                  this.button = "Você está cadastrado(a) :)";
                  var msg = "Bem-vindo à Desbravalley!";
                  this.$swal({
                    title: "Conta Criada!",
                    text: msg,
                    type: "success",
                    timer: 1000,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                  })
                    .then(() => {
                      this.$swal({
                        title: "Tudo certo!",
                        text: "Seu cadastro foi efetuado. No topo da tela, há um botão escrito 'Voltar', que você usará para ir para a tela de login. Quando chegar na tela de login, use o e-mail e senha que você definiu no cadastro e faça seu login :)",
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                      });
                    })
                    .catch((error) => {
                      this.$swal({
                        title: "Oops :|",
                        text: "Tivemos um pequeno problema. Por favor, tente novamente mais tarde.",
                        showCancelButton: false,
                        confirmButtonText: "Ok",
                        confirmButtonColor: "#6fe335",
                      });
                      this.button = "Cadastrar-se";
                      document.getElementsByClassName("signup__submit")[0].removeAttribute("disabled");
                    });
                }
              })
              .catch((error) => {
                if (error.response.data.errors) {
                  for (const item in error.response.data.errors) {
                    if (["password", "password_confirmation", "email", "phone", "name"].includes(item)) {
                      this.address_turn = false;
                    }
                  }
                  this.$swal({
                    title: "Oops :|",
                    text: "Encontramos alguns erros no seu formulário, por favor, verifique os campos que estão em vermelho",
                    showCancelButton: false,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#6fe335",
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    allowEscapeKey: false,
                  }).then((confirm) => {
                    if (confirm.value) {
                      for (const item in error.response.data.errors) {
                        if (item === "neibourhood") {
                          this.assignError(document.getElementById("neighbourhood"), error.response.data.errors[item]);
                          continue;
                        } else if (item === "password_confirmation") {
                          this.assignError(document.getElementById("confirms"), error.response.data.errors[item]);
                          continue;
                        }
                        this.assignError(document.getElementById(item), error.response.data.errors[item]);
                      }
                    }
                  });
                } else if (error.response.data.length > 0) {
                  this.address_turn = false;
                  this.$swal({
                    title: "Oops :|",
                    text: "Encontramos alguns erros no seu formulário, por favor, verifique os campos que estão em vermelho",
                    showCancelButton: false,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#6fe335",
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    allowEscapeKey: false,
                  }).then((confirm) => {
                    if (confirm.value) {
                      for (const item in error.response.data.message) {
                        const error_message = error.response.data.message[item];
                        if (item === "cpf") {
                          this.assignError(document.getElementById(item), error_message ? error_message : "CPF inválido");
                        } else if (item === "cnpj") {
                          this.assignError(document.getElementById(item), error_message ? error_message : "CNPJ inválido");
                        } else if (item === "social-name") {
                          this.assignError(document.getElementById(item), error_message ? error_message : "Razão Social inválida");
                        } else if (item === "state-registration") {
                          this.assignError(document.getElementById(item), error_message ? error_message : "Inscrição Estadual inválida");
                        } else if (item === "responsible") {
                          this.assignError(document.getElementById(item), error_message ? error_message : "Responsável inválido");
                        } else if (item === "position") {
                          this.assignError(document.getElementById(item), error_message ? error_message : "Cargo inválido");
                        }
                      }
                    }
                  });
                } else {
                  this.$swal({
                    title: "Oops :|",
                    text: "Tivemos um pequeno problema. Por favor, tente novamente mais tarde.",
                    showCancelButton: false,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#6fe335",
                  });
                }
                this.button = "Cadastrar-se";
                document.getElementsByClassName("signup__submit")[0].removeAttribute("disabled");
              });
          }
          window.scrollTo(0, 0);
          document.getElementById("signup-change").innerHTML =
            "Olá, <strong>" + this.form.name + "</strong>, somente precisamos de mais alguns dados.";
          document.getElementById("signup-change").classList.add("blink");
          document.getElementsByClassName("signup__submit")[0].removeAttribute("disabled");
        };
        sleep();
      } else if (is_form_ok && this.address_turn && this.is_cep_ok) {
        this.removeErrorMessages();
        const sleepingPromise = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
        this.button = "Cadastrando você...";
        document.getElementsByClassName("signup__submit")[0].disabled = true;
        const sleep = async () => {
          await sleepingPromise(1000);
          this.address.neibourhood = this.address.neighbourhood;
          if (this.form.person_type !== "pf" && !this.form.state_registration_checkbox && this.form.state_registration === "") {
            this.form.state_registration_checkbox = true;
            this.form.state_registration = "Isento";
          }
          if (this.form.person_type == "pf") {
            delete this.form.state_registration_checkbox;
            delete this.form.state_registration;
          }
          axios
            .post(this.register_endpoint, {
              client: this.form,
              address: this.address,
            })
            .then((response) => {
              if (response.status == 201) {
                this.button = "Você está cadastrado(a) :)";
                var msg = "Bem-vindo à Desbravalley!";
                this.$swal({
                  title: "Conta Criada!",
                  text: msg,
                  type: "success",
                  timer: 1000,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                })
                  .then(() => {
                    if (location.href.split("/")[4] != "carrinho" && this.is_app !== 0) {
                      this.$swal({
                        title: "Tudo certo!",
                        text: "Seu cadastro foi efetuado. No topo da tela, há um botão escrito 'Voltar', que você usará para ir para a tela de login. Quando chegar na tela de login, use o e-mail e senha que você definiu no cadastro e faça seu login :)",
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                      });
                    } else {
                      axios
                        .post(this.login_endpoint, {
                          email: this.form.email,
                          password: this.form.password,
                        })
                        .then((response) => {
                          console.log(response);
                          this.setWithExpiry("__AUTH", response.data[0], 1296000000, this.form.email);
                          this.$emit("signupStep");
                        })
                        .finally(() => {
                          this.button = "Cadastrar-se";
                          document.getElementsByClassName("signup__submit")[0].removeAttribute("disabled");
                        });
                    }
                  })
                  .catch((error) => {
                    this.$swal({
                      title: "Oops :|",
                      text: "Tivemos um pequeno problema. Por favor, tente novamente mais tarde.",
                      showCancelButton: false,
                      confirmButtonText: "Ok",
                      confirmButtonColor: "#6fe335",
                    });
                    this.button = "Cadastrar-se";
                    document.getElementsByClassName("signup__submit")[0].removeAttribute("disabled");
                  });
              }
            })
            .catch((error) => {
              if (error.response.data.errors) {
                for (const item in error.response.data.errors) {
                  if (["password", "password_confirmation", "email", "phone", "name"].includes(item)) {
                    this.address_turn = false;
                  }
                }
                this.$swal({
                  title: "Oops :|",
                  text: "Encontramos alguns erros no seu formulário, por favor, verifique os campos que estão em vermelho",
                  showCancelButton: false,
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#6fe335",
                  allowOutsideClick: false,
                  closeOnClickOutside: false,
                  allowEscapeKey: false,
                }).then((confirm) => {
                  if (confirm.value) {
                    for (const item in error.response.data.errors) {
                      if (item === "neibourhood") {
                        this.assignError(document.getElementById("neighbourhood"), error.response.data.errors[item]);
                        continue;
                      } else if (item === "password_confirmation") {
                        this.assignError(document.getElementById("confirms"), error.response.data.errors[item]);
                        continue;
                      }
                      this.assignError(document.getElementById(item), error.response.data.errors[item]);
                    }
                  }
                });
              } else if (error.response.data.length > 0) {
                this.address_turn = false;
                this.$swal({
                  title: "Oops :|",
                  text: "Encontramos alguns erros no seu formulário, por favor, verifique os campos que estão em vermelho",
                  showCancelButton: false,
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#6fe335",
                  allowOutsideClick: false,
                  closeOnClickOutside: false,
                  allowEscapeKey: false,
                }).then((confirm) => {
                  if (confirm.value) {
                    for (const item in error.response.data.message) {
                      const error_message = error.response.data.message[item];
                      if (item === "cpf") {
                        this.assignError(document.getElementById(item), error_message ? error_message : "CPF inválido");
                      } else if (item === "cnpj") {
                        this.assignError(document.getElementById(item), error_message ? error_message : "CNPJ inválido");
                      } else if (item === "social-name") {
                        this.assignError(document.getElementById(item), error_message ? error_message : "Razão Social inválida");
                      } else if (item === "state-registration") {
                        this.assignError(document.getElementById(item), error_message ? error_message : "Inscrição Estadual inválida");
                      } else if (item === "responsible") {
                        this.assignError(document.getElementById(item), error_message ? error_message : "Responsável inválido");
                      } else if (item === "position") {
                        this.assignError(document.getElementById(item), error_message ? error_message : "Cargo inválido");
                      }
                    }
                  }
                });
              } else {
                this.$swal({
                  title: "Oops :|",
                  text: "Tivemos um pequeno problema. Por favor, tente novamente mais tarde.",
                  showCancelButton: false,
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#6fe335",
                });
              }
              this.button = "Cadastrar-se";
              document.getElementsByClassName("signup__submit")[0].removeAttribute("disabled");
            });
        };
        sleep();
      }
    },
    setWithExpiry(key, value, ttl, email) {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      };
      localStorage.setItem(key, JSON.stringify(item));
      if (location.href.split("/")[4] != "carrinho" && this.is_app === 0) {
        window.location.href = this.home_endpoint;
      } else {
        let tmp = JSON.parse(localStorage.getItem("__TMP"));
        tmp.email = email;
        localStorage.setItem("__TMP", JSON.stringify(tmp));
        axios.post("/api/cart/attribution", {
          temp: localStorage.getItem("__TMP"),
          client_id: JSON.parse(localStorage.getItem("__AUTH")).value,
        });
      }
    },
    async isFormOk() {
      this.removeErrorMessages();
      var is_ok = true;
      if (!this.address_turn) {
        //Checks if the name has more than two words if it's a PF
        var name = document.getElementById("name");
        if (this.form.person_type == "pf" && !(name.value.indexOf(" ") >= 0)) {
          const message = "Digite nome e sobrenome, por favor";
          this.assignError(name, message);
          is_ok = false;
          LogHelper.sendLogToGoogleChat(`Formulário de cadastro | ${message}`, 406);
        }

        //Checks if both the password and its confirmation are the same
        var password = document.getElementById("password");
        var confirms = document.getElementById("confirms");
        if (password.value != confirms.value) {
          const message = "Digite senhas iguais, por favor";
          this.assignError(password, message);
          this.assignError(confirms, message);
          LogHelper.sendLogToGoogleChat(`Formulário de cadastro (senha e confirmação não batem) | ${message}`, 406);
          is_ok = false;
        }

        //Checks if the password has at least 6 digits
        if (password.value.length < 6) {
          const message = "A senha precisa ter 6 caracteres, no mínimo";
          this.assignError(password, message);
          is_ok = false;
          LogHelper.sendLogToGoogleChat(`Formulário de cadastro | ${message}`, 406);
        }

        // Checks if all basic fields are fielled
        var items = ["name", "email", "password", "confirms"];
        for (var i = 0; i < items.length; i++) {
          var element = document.getElementById(items[i]);
          if (element.value.length < 1) {
            const message = "Este campo é obrigatório";
            this.assignError(element, message);
            is_ok = false;
            LogHelper.sendLogToGoogleChat(`Formulário de cadastro (${items[i]}) | ${message}`, 406);
          }
        }

        if (this.form.person_type == "pf") {
          //Checks if the CPF is a valid one
          if (this.form.cpf) {
            var cpf = document.getElementById("cpf");
            if (!this.checkCPF(cpf.value)) {
              const message = "Digite um CPF válido, por favor";
              this.assignError(cpf, message);
              is_ok = false;
              LogHelper.sendLogToGoogleChat(`Formulário de cadastro | ${message}`, 406);
            }
          }

          //Checks if the birthday date is complete
          // var birthday_date = document.getElementById("birthday");
          // if (birthday_date.value.length < 10) {
          //   const message = "Digite uma data válida, por favor";
          //   this.assignError(birthday_date, message);
          //   is_ok = false;
          //   LogHelper.sendLogToGoogleChat(
          //     `Formulário de cadastro | ${message}`,
          //     406
          //   );
          // }
          // if (birthday_date.value.split("/")[2] == "2021") {
          //   const message = "Digite um ano válido, por favor";
          //   this.assignError(birthday_date, message);
          //   is_ok = false;
          //   LogHelper.sendLogToGoogleChat(
          //     `Formulário de cadastro | ${message}`,
          //     406
          //   );
          // }

          //Checks if all the fields are filled
          // var items = ["cpf"];
          // for (var i = 0; i < items.length; i++) {
          //   var element = document.getElementById(items[i]);
          //   if (element.value.length < 1) {
          //     const message = "Este campo é obrigatório";
          //     this.assignError(element, message);
          //     is_ok = false;
          //     LogHelper.sendLogToGoogleChat(
          //       `Formulário de cadastro (${items[i]}) | ${message}`,
          //       406
          //     );
          //   }
          // }
        }

        if (this.form.person_type == "pj") {
          //Checks if the CPF is a valid one
          var cnpj = document.getElementById("cnpj");
          if (!this.checkCNPJ(cnpj.value)) {
            const message = "Digite um CNPJ válido, por favor";
            this.assignError(cnpj, message);
            is_ok = false;
            LogHelper.sendLogToGoogleChat(`Formulário de cadastro | ${message}`, 406);
          }

          //Checks if all the fields are filled
          var items = ["social-name", "cnpj", "responsible", "position"];
          for (var i = 0; i < items.length; i++) {
            var element = document.getElementById(items[i]);
            if (element.value.length < 1) {
              const message = "Este campo é obrigatório";
              this.assignError(element, message);
              is_ok = false;
              LogHelper.sendLogToGoogleChat(`Formulário de cadastro ${items[i]} | ${message}`, 406);
            }
          }
        }
      } else {
        // Checks if the address is filled too
        // var items = [
        //   "cep",
        //   "street",
        //   "neighbourhood",
        //   "number",
        //   "state",
        //   "city",
        // ];
        // for (var i = 0; i < items.length; i++) {
        //   var element = document.getElementById(items[i]);
        //   if (element.value.length < 1) {
        //     const message = "Este campo é obrigatório";
        //     this.assignError(element, message);
        //     is_ok = false;
        //     LogHelper.sendLogToGoogleChat(
        //       `Formulário de cadastro ${items[i]} | ${message}`,
        //       406
        //     );
        //   }
        // }
      }
      document.getElementsByClassName("signup__submit")[0].removeAttribute("disabled");
      return is_ok;
    },
    assignError(element, message) {
      //Assign an error message
      element.parentElement.dataset.wronged = message;
      element.parentElement.classList.add("ui-wronged");
    },
    assignSuccess(element, message) {
      //Assign a success message
      element.parentElement.dataset.wronged = message;
      element.parentElement.classList.add("ui-correct");
    },
    changesState() {
      //Changes the cities available to correspond with the selceted state
      for (var i = 0; i < this.locations.estados.length; i++) {
        if (this.locations.estados[i].nome == this.address.state) {
          this.cities = this.locations.estados[i].cidades;
        }
      }
    },
    viaCep() {
      //Changes the inputs related to the address based on the typed CEP
      if (this.address.cep.length == 9) {
        this.removeErrorMessages();
        axios.get("https://viacep.com.br/ws/" + this.address.cep + "/json").then((response) => {
          if (response.data.erro === "true") {
            const message = "Digite um CEP válido";
            document.getElementById("cep").parentElement.classList.remove("ui-correct");
            this.assignError(document.getElementById("cep"), message);
            this.is_cep_ok = false;
            this.address.street = "";
            this.address.complement = "";
            this.address.neighbourhood = "";
            this.address.city = "";
            this.address.state = "";
            LogHelper.sendLogToGoogleChat(`Formulário de Cadastro | ${message}`);
          } else {
            document.getElementById("cep").parentElement.classList.remove("ui-wronged");
            this.assignSuccess(document.getElementById("cep"), "Este é um CEP válido :)");
            this.is_cep_ok = true;
            this.address.cep = response.data.cep;
            this.address.street = response.data.logradouro;
            this.address.complement = response.data.complemento;
            this.address.neighbourhood = response.data.bairro;
            this.address.city = response.data.localidade;
            for (var i = 0; i < this.locations.estados.length; i++) {
              if (this.locations.estados[i].sigla == response.data.uf) {
                this.address.state = this.locations.estados[i].nome;
                break;
              }
            }
            this.changesState();
          }
        });
      }
    },
    checkCPF(cpf) {
      //Separates the numbers before and after the '-'
      var cpf = cpf.replaceAll(".", "");
      var cpf = cpf.replaceAll("-", "");
      var cpf_validation = cpf.slice(9, 12);
      var cpf_operation = cpf.slice(0, 9);

      //Sums each number before the '-' multiplied by (10..2)
      var sum = 0;
      for (var i = 10; i >= 2; i--) {
        sum += +cpf_operation[10 - i] * i;
      }

      //Get the rest of the sum, if it's 11 or 10, it'll be zero
      var rest = (sum * 10) % 11;
      if (rest == 10 || rest == 11) {
        rest = 0;
      }

      //If the rest is different from the first digit, it's already invalid
      if (rest != +cpf_validation[0]) {
        return false;
      }

      //Repeat for the next digit of validation
      var cpf_validation = cpf.slice(10, 12);
      var cpf_operation = cpf.slice(0, 11);

      //Sums each number before the last digit multiplied by (11..2)
      var sum = 0;
      for (var i = 11; i >= 2; i--) {
        sum += +cpf_operation[11 - i] * i;
      }

      //Get the rest of the sum, if it's 11 or 10, it'll be zero
      var rest = (sum * 10) % 11;
      if (rest == 10 || rest == 11) {
        rest = 0;
      }

      //If the rest is different from the first digit, it's already invalid
      if (rest != +cpf_validation[0]) {
        return false;
      } else {
        return true;
      }
    },
    checkCNPJ(cnpj) {
      if (!cnpj) return false;

      // Aceita receber o valor como string, número ou array com todos os dígitos
      const isString = typeof cnpj === "string";
      const validTypes = isString || Number.isInteger(cnpj) || Array.isArray(cnpj);

      // Elimina valor em formato inválido
      if (!validTypes) return false;

      // Filtro inicial para entradas do tipo string
      if (isString) {
        // Limita ao máximo de 18 caracteres, para CNPJ formatado
        if (cnpj.length > 18) return false;

        // Teste Regex para veificar se é uma string apenas dígitos válida
        const digitsOnly = /^\d{14}$/.test(cnpj);
        // Teste Regex para verificar se é uma string formatada válida
        const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(cnpj);

        // Se o formato é válido, usa um truque para seguir o fluxo da validação
        if (digitsOnly || validFormat) true;
        // Se não, retorna inválido
        else return false;
      }

      // Guarda um array com todos os dígitos do valor
      const match = cnpj.toString().match(/\d/g);
      const numbers = Array.isArray(match) ? match.map(Number) : [];

      // Valida a quantidade de dígitos
      if (numbers.length !== 14) return false;

      // Elimina inválidos com todos os dígitos iguais
      const items = [...new Set(numbers)];
      if (items.length === 1) return false;

      // Cálculo validador
      const calc = (x) => {
        const slice = numbers.slice(0, x);
        let factor = x - 7;
        let sum = 0;

        for (let i = x; i >= 1; i--) {
          const n = slice[x - i];
          sum += n * factor--;
          if (factor < 2) factor = 9;
        }

        const result = 11 - (sum % 11);

        return result > 9 ? 0 : result;
      };

      // Separa os 2 últimos dígitos de verificadores
      const digits = numbers.slice(12);

      // Valida 1o. dígito verificador
      const digit0 = calc(12);
      if (digit0 !== digits[0]) return false;

      // Valida 2o. dígito verificador
      const digit1 = calc(13);
      return digit1 === digits[1];
    },
  },
};
</script>

<template>
  <div>
    <div class="text-center" v-if="trilha_selecionada === ''">
      <h1 class="text-white">
        <b>Parque Desbravalley <br>
          06 a 15 de Outubro de 2023 <br>
          Onde a Inovação encontra impacto!
        </b>
      </h1>
      <h2 class="text-white mt-5">Confira a programação de cada dia</h2>
    </div>
    <div class="select_day space-all text-center text-color subtitle-size space_new text-white" v-if="trilha_selecionada === ''">
      <b> Selecione o dia: </b>
    </div>
    <div class="days-area space-bottom" :class="trilha_selecionada === '' ? '' : 'pt-5'">
      <ul>
        <li
          class="filho"
          v-for="(day, index) in days"
          :key="index"
          :class="index == 0 ? 'active-day' : ''"
          :id="'liDay' + index"
          @click="mudaData(index, day)"
        >
          <div class="days-area-day">Dia <br> {{ moment(day).format("D") }}</div>
          <div class="days-area-weekday">
            {{ moment(day).format("MMMM") }}
          </div>
          <div :id="'day' + index"></div>
        </li>
      </ul>
    </div>
    <div class="fixed_days" @click="scrollTop()">
      {{ moment(day_selected).format("D/MMM") }}
    </div>
    <div class="programation-area">
      <div v-for="(item, index) in palestras" :key="index" v-show="filtrarPalestrantes(item.palestras).length > 0">
        <div class="programation-area-day-time">
          <b>
            {{ item.periodo }}
          </b>
        </div>
        <ul id="pai" v-if="filtrarPalestrantes(item.palestras).length > 0">
          <li v-for="(palest, idx) in filtrarPalestrantes(item.palestras)" :key="idx">
            <div class="row align-items-stretch" v-if="palest.palestrantes">
              <div class="col-12 col-md-3 d-none">
                <div class="programation-area-images">
                  <div v-if="palest.palestrantes.length === 1" class="h-100">
                    <div v-if="palest.palestrantes[0].img" class="ui-image--contain programation-area-image" :style="'background-image:url(' + palest.palestrantes[0].img + ')'"></div>
                    <div v-else class="ui-image--contain programation-area-image center_important" :style="'background-image:url(/img/logodesbrava.png)'"></div>
                  </div>
                  <!-- two_images -->
                  <!-- three_images -->
                  <!-- four_images -->
                  <!-- five_images -->
                  <!-- six_images -->
                  <!-- seven_images -->
                  <!-- eight_images -->
                  <div v-else :class="retornaClasseImagens(palest.palestrantes)">
                    <div
                      v-for="(t, ts) in palest.palestrantes"
                      :key="ts"
                      class="ui-image--contain programation-area-image"
                      :style="'background-image:url(' + retornaImg(t.img) + ')'"
                    >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-9 py-lg-1 pl-md-4 pr-md-0">
                <div class="row align-items-center h-100 mx-0">
                  <div class="pt-2 px-md-0">
                    <div class="programation-area-title">
                      {{ palest.nome_palestra }}
                    </div>
                    <div class="programation-area-user" v-if="palest.palestrantes.length === 1">
                      <span>{{ palest.palestrantes[0].nome_palestrante }}</span>
                      <p>{{ palest.palestrantes[0].minibio }}</p>
                    </div>
                    <!-- add classe various -->
                    <div v-else class="programation-area-user" v-for="(t, ts) in palest.palestrantes" :key="ts">
                      <div class="mx-0">
                        <span>{{ t.nome_palestrante }}</span>
                        <p>{{ t.minibio }}</p>
                      </div>
                    </div>
                    <div class="descricao">
                      {{ palest.palestra_descricao }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 pt-md-2 pb-md-3 bottom_space_mobile">
                <div class="row align-items-center h-100 mx-0">
                  <div class="d-flex-trilha">
                    <!-- <div class="programation-area-info">Trilha{{ palest.trilhas.length > 1 ? "s" : "" }}</div> -->
                    <!-- <div
                      v-for="(trilha, indexTrrilha) in palest.trilhas"
                      :key="indexTrrilha"
                      class="programation-area-info-box mb-1"
                      :style="'background-color: ' + trilha.cor + ';color:' + mudaCor(trilha.cor)"
                    >
                      {{ trilha.trilhaNome }}
                    </div> -->
                    <div class="programation-area-info mt-1">Local</div>
                    <div
                      class="programation-area-info-box"
                      :style="'background-color: ' + palest.cor_plenaria + ';color:' + mudaCor(palest.cor_plenaria)"
                    >
                      {{ palest.plenaria }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="show_aviso text-center" v-if="showMessage">Em breve, conteúdos e profissionais inimagináveis estarão por aqui :)</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/pt-br";
export default {
  props: ["palestras", "trilhas", "days"],
  data() {
    return {
      selected: [],
      filtros_trilhas: [],
      data_selecionada: 0,
      contagem: 0,
      day_selected: "",
      scrolled: false,
      trilha_selecionada: "",
      showMessage: false,
    };
  },
  methods: {
    moment,
    retornaImg (img) {
      if(img) {
        return img
      } else {
        return '/img/logodesbrava.png'
      }
    },
    filtraTrilha(id, retorno = false) {
      if (this.verificaClass(id) || retorno) {
        document.getElementById(id).classList.remove("active");
        const index = this.selected.indexOf(id);
        if (index > -1) {
          this.selected.splice(index, 1);
        }
      } else {
        document.getElementById(id).classList.add("active");
        this.selected.push(id);
      }
      this.getShowMessage();
      // document.getElementsByClassName('programation-area')[0].scrollIntoView({block: 'start', behavior: 'smooth'})
    },
    filtraTrilhaUrl(id) {
      let numero = Number(id);
      this.selected.push(numero);
      // document.getElementsByClassName('programation-area')[0].scrollIntoView({block: 'start', behavior: 'smooth'})
    },
    verificaClass(id) {
      var pai = document.getElementById(id);
      if (pai.classList.contains("active")) {
        return true;
      } else {
        return false;
      }
    },
    mudaData(li, dia) {
      var ativo = document.getElementsByClassName("active-day");
      for (let i = 0; i < ativo.length; i++) {
        const element = ativo[i];
        element.classList.remove("active-day");
      }
      document.getElementById("liDay" + li).classList.add("active-day");
      this.data_selecionada = li;
      this.day_selected = dia;
      // document.getElementsByClassName('programation-area')[0].scrollIntoView({block: 'start', behavior: 'smooth'})
      this.getShowMessage();
    },
    filtrarPalestrantes(dados) {
      dados = dados.filter((item) => item.data.toLowerCase() == this.days[this.data_selecionada].toLowerCase());
      if (this.selected.length > 0 && this.selected[0] != 0) {
        dados = dados.filter((item) => {
          const f = item.trilhas.filter((elem) => this.selected.includes(elem.trilhaId));
          if (f.length > 0) {
            return true;
          }
          return false;
        });
      }
      return dados;
    },
    getShowMessage() {
      setTimeout(() => {
        let pai = document.getElementById("pai");
        // let filho = pai.querySelector(".filho");
        if (pai) {
          this.showMessage = false;
        } else {
          this.showMessage = true;
        }
      }, 300);
    },
    retornaTrilha() {
      let trilhaSelected = "";
      this.trilhas.filter((item) => {
        if (item.id === Number(this.trilha_selecionada)) {
          trilhaSelected = item.nome;
        }
      });
      return trilhaSelected;
    },
    mudaCor(hexcolor) {
      if (hexcolor.slice(0, 1) === "#") {
        hexcolor = hexcolor.slice(1);
      }
      if (hexcolor.length === 3) {
        hexcolor = hexcolor
          .split("")
          .map(function (hex) {
            return hex + hex;
          })
          .join("");
      }
      let r = parseInt(hexcolor.substr(0, 2), 16);
      let g = parseInt(hexcolor.substr(2, 2), 16);
      let b = parseInt(hexcolor.substr(4, 2), 16);
      let yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "black" : "white";
    },
    retornaClasseImagens(p) {
      //two_images
      //three_images
      //four_images
      //five_images
      //six_images
      //seven_images
      //eight_images
      switch (p.length) {
        case 1:
          return "";
          break;
        case 1:
          return "";
          break;
        case 2:
          return "two_images";
          break;
        case 3:
          return "three_images";
          break;
        case 4:
          return "four_images";
          break;
        case 5:
          return "five_images";
          break;
        case 6:
          return "six_images";
          break;
        case 7:
          return "seven_images";
          break;
        case 8:
          return "eight_images";
          break;
        default:
          return "";
          break;
      }
    },
    filtrarHorarios() {
      let programacaoDia = [];
      for (let prog in this.palestras) {
        for (let pales in this.palestras[prog].palestras) {
          if (this.palestras[prog].palestras[pales].data_filtro == this.day_selected) {
            programacaoDia.push(this.palestras[prog].palestras[pales]);
          }
        }
      }
      console.log(programacaoDia);
    },
    scrollTop() {
      document.getElementsByClassName("days-area")[0].scrollIntoView({ block: "start", behavior: "smooth" });
    },
    rolagem() {
      let objeto = document.querySelector(".days-area");
      let { top, bottom } = objeto.getBoundingClientRect();
      // let height = document.documentElement.clientHeight;
      // this.scrolled = top < height && bottom >0;
      // console.log('top', top)
      // console.log('bottom', bottom)
      // console.log('height', height)
      // console.log(objeto.offsetTop)
      let div = document.querySelector(".fixed_days");
      if (top <= -120) {
        div.classList.add("show_days");
      } else {
        div.classList.remove("show_days");
      }
      // if (objeto.offset().top === 0) {
      //   console.log('aqui')
      // }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.rolagem);
  },
  created() {
    // console.log('palestras', this.palestras)
    // console.log('trilhas', this.trilhas)
    // console.log('dias', this.days)

    // console.log(this.days, "aqui");
    // let teste = this.days;
    // teste.sort(function (a, b) {
    //   return new Date(a) - new Date(b);
    // });
    // teste = this.days;
    // console.log(teste, "aqui");
    // this.filtrarHorarios()
    if (window.location.search) {
      new URL(location.href).searchParams.get("trilha");
      const params = new URL(location.href).searchParams;
      let trilha = params.get("trilha");
      if (trilha) {
        this.trilha_selecionada = trilha;
        console.log(this.trilha_selecionada, "TRILHA");
        setTimeout(() => {
          this.filtraTrilhaUrl(this.trilha_selecionada);
        }, 100);
      }
    }
    this.day_selected = this.days[0];
    window.addEventListener("scroll", this.rolagem);
    // setTimeout(() => {
    //   document.getElementById("loading-all").style.display = "none";
    // }, 1500);
    if (window.location.search) {
      let indexData = "";
      let dataSelecionada = "";
      new URL(location.href).searchParams.get("data");
      const param = new URL(location.href).searchParams;
      let data = param.get("data");
      let dataMoment = data.split("-");
      let dataAmericana = dataMoment[2] + "-" + dataMoment[1] + "-" + dataMoment[0];
      for (let index = 0; index < this.days.length; index++) {
        if (dataAmericana === this.days[index]) {
          indexData = index;
          dataSelecionada = this.days[index];
        }
      }
      setTimeout(() => {
        if (indexData && dataSelecionada) {
          this.mudaData(indexData, dataSelecionada);
        }
      }, 1600);
    }
    setTimeout(() => {
      this.getShowMessage();
    }, 1700);
  },
};
</script>

<style lang="scss" scoped>
@import "resources/assets/sass/website/_variables.scss";
$primary-color: #7e7e7e;
$secondary-color: #000;
$tertiary-color: #35930e;
$text-color: #000;
$string-color: #929292;

html {
  scroll-behavior: smooth;
}
* {
  font-family: $font-hero;
}
.font-extrabold {
  font-family: "Herokid-ExtraBold";
}
.font-bold {
  font-family: "Herokid-SemiBold";
}
ul {
  padding-left: 0;
}
li {
  list-style-type: none;
}
.primary-color {
  color: $primary-color;
}
.secondary-color {
  color: $secondary-color;
}
.tertiary-color {
  color: $tertiary-color;
}
.text-color {
  color: $text-color;
}
h1 {
  color: $secondary-color;
  font-size: 55px;
  font-family: "Herokid" !important;
  padding-top: 50px;
  margin-bottom: 10px;
  @media (max-width: 1500px) {
    font-size: 45px;
    padding-top: 40px;
  }
  @media (max-width: 991px) {
    font-size: 30px;
    padding-top: 20px;
  }
  @media (max-width: 767px) {
    font-size: 26px;
  }
}
.subtitle-size {
  font-size: 20px;
  @media (max-width: 991px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.first-subtitle {
  color: $secondary-color;
  font-family: "Herokid" !important;
  line-height: normal;
  max-width: 700px;
  margin: 0 auto;
  @media (max-width: 991px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.subtitle {
  color: $secondary-color;
  // max-width: 270px;
  margin: 0 auto;
  line-height: normal;
}
.space-all {
  margin: 30px 0;
  @media (max-width: 991px) {
    margin: 20px 0;
  }
}
.space-bottom {
  margin-bottom: 30px;
  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
}
.space-top {
  margin-top: 30px;
  @media (max-width: 991px) {
    margin-top: 20px;
  }
}
.space_new {
  margin-bottom: 10px !important;
}
.trilhas_lista {
  @extend .space-bottom;
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    li {
      align-items: center;
      border-radius: 50px;
      border: 1px transparent solid;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      font-weight: 300;
      outline: none;
      margin: 2px 3px;
      padding: 5px 10px;
      transition: all 0.3s;
      user-select: none;
      img {
        margin-left: 5px;
        margin-right: 5px;
        max-width: 40px;
      }
      &:hover {
        color: $tertiary-color;
        font-weight: 900;
        transition: all 0.3s;
      }
    }
    .active {
      border-color: $primary-color;
      color: $primary-color;
      font-weight: 900;
      transition: all 0.3s;
    }
  }
}
.days-area {
  @media (max-width: 991px) {
    overflow-x: auto;
  }
  ul {
    display: flex;
    li {
      background-color: $secondary-color;
      color: white;
      cursor: pointer;
      flex: 1;
      outline: none;
      padding: 20px 10px;
      text-align: center;
      transition: all 0.3s;
      user-select: none;
      &:nth-of-type(1) {
        border-radius: 25px 0 0 25px;
      }
      &:nth-last-of-type(1) {
        border-radius: 0 25px 25px 0;
      }
      &:hover {
        background-color: $primary-color;
        opacity: 0.6;
        transition: all 0.3s;
      }
    }
  }
  &-day {
    font-size: 30px;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  &-weekday {
    font-size: 18px;
    text-transform: capitalize;
    @media (max-width: 767px) {
      font-size: 13px;
    }
  }
  .active-day {
    background-color: $tertiary-color;
    transition: all 0.3s;
    * {
      font-weight: bold;
    }
  }
}
.programation-area {
  padding-bottom: 30px;
  &.programation-area:has(ul) {
    .show_aviso {
      display: none;
    }
  }
  li {
    background-color: white;
    border-radius: 15px;
    margin-bottom: 20px;
    overflow: hidden;
    @media (max-width: 991px) {
      margin-bottom: 15px;
    }
  }
  &-day-time {
    b {
      @extend .font-bold;
    }
  }
  &-images {
    // background-color: rgb(255, 250, 231);
    height: 100%;
    @media (max-width: 991px) {
      height: 250px;
    }
    @media (max-width: 767px) {
      height: auto;
    }
  }
  &-image {
    background-position: center left !important;
    border-radius: 15px 0 0 15px;
    height: 215px;
    @media (max-width: 1200px) {
      height: 176px;
    }
    @media (max-width: 991px) {
      height: 126px;
    }
    @media (max-width: 767px) {
      height: 200px;
      background-position: center top !important;
      border-radius: 15px 15px 0 0;
    }
  }
  &-title {
    color: $text-color;
    font-size: 17px;
    @extend.font-bold;
    line-height: 20px;
    word-break: break-word;
    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }
  .two_images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .programation-area-image {
      // aspect-ratio: 3/3;
      min-height: inherit !important;
      width: 100%;
      height: 215px;
      @media (max-width: 1200px) {
        height: 176px;
      }
      @media (max-width: 991px) {
        height: 126px;
      }
      @media (max-width: 767px) {
        height: 200px;
        background-position: center top !important;
        border-radius: 15px 15px 0 0;
      }
      &:nth-of-type(1) {
        border-radius: 15px 0px 0px 0px;
        @media (max-width: 767px) {
          border-radius: 15px 15px 0 0;
        }
      }
      &:nth-of-type(2) {
        border-radius: 0px 0px 0px 15px;
        @media (max-width: 767px) {
          border-radius: 0px 0px 0 0;
        }
      }
    }
  }
  .three_images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .programation-area-image {
      height: 107px;
      @media (max-width: 767px) {
        height: 146px;
      }
      &:nth-of-type(1) {
        border-radius: 15px 0px 0px 0px;
        height: 215px;
        width: 100%;
        @media (max-width: 1200px) {
          height: 176px;
        }
        @media (max-width: 991px) {
          height: 126px;
        }
        @media (max-width: 767px) {
          height: 200px;
          background-position: center top !important;
          border-radius: 15px 15px 0 0;
        }
      }
      &:nth-of-type(2) {
        border-radius: 0px 0px 0px 0px;
        width: 50%;
      }
      &:nth-of-type(3) {
        border-radius: 0px 0px 0px 0px;
        width: 50%;
      }
    }
  }
  .four_images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .programation-area-image {
      height: 107px;
      @media (max-width: 991px) {
        height: 63px;
      }
      @media (max-width: 767px) {
        height: 146px;
      }
      &:nth-of-type(1) {
        border-radius: 15px 0px 0px 0px;
        width: 50%;
      }
      &:nth-of-type(2) {
        border-radius: 0px 0px 0px 0px;
        width: 50%;
      }
      &:nth-of-type(3) {
        border-radius: 0px 0px 0px 15px;
        @media (max-width: 767px) {
          border-radius: 0px;
        }
        width: 50%;
      }
      &:nth-of-type(4) {
        border-radius: 0px 0px 0px 0px;
        width: 50%;
      }
    }
  }
  .five_images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .programation-area-image {
      height: 107px;
      &:nth-of-type(1) {
        border-radius: 15px 0px 0px 0px;
        height: 215px;
        width: 100%;
        @media (max-width: 1200px) {
          height: 176px;
        }
        @media (max-width: 991px) {
          height: 126px;
        }
      }
      &:nth-of-type(2) {
        border-radius: 0px 0px 0px 0px;
        width: 50%;
      }
      &:nth-of-type(3) {
        border-radius: 0px 0px 0px 0px;
        width: 50%;
      }
      &:nth-of-type(4) {
        border-radius: 0px 0px 0px 15px;
        width: 50%;
      }
      &:nth-of-type(5) {
        border-radius: 0px 0px 0px 0px;
        width: 50%;
      }
    }
  }
  .six_images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .programation-area-image {
      &:nth-of-type(1) {
        border-radius: 15px 0px 0px 0px;
        width: 100%;
      }
      &:nth-of-type(2) {
        border-radius: 0px 0px 0px 0px;
        width: 50%;
      }
      &:nth-of-type(3) {
        border-radius: 0px 0px 0px 0px;
        width: 50%;
      }
      &:nth-of-type(4) {
        border-radius: 0px 0px 0px 0px;
        width: 50%;
      }
      &:nth-of-type(5) {
        border-radius: 0px 0px 0px 15px;
        width: 50%;
      }
      &:nth-of-type(6) {
        border-radius: 0px 0px 0px 0px;
        width: 50%;
      }
    }
  }
  .seven_images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .programation-area-image {
      height: 50%;
      width: 33%;
      &:nth-last-of-type(1) {
        width: 25%;
      }
      &:nth-last-of-type(2) {
        width: 25%;
      }
      &:nth-last-of-type(3) {
        width: 25%;
      }
      &:nth-last-of-type(4) {
        width: 25%;
      }
    }
  }
  .eight_images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .programation-area-image {
      height: 120px;
      width: 50%;
    }
  }
  &-user {
    span {
      font-size: 17px;
      line-height: normal;
      @media (max-width: 1200px) {
        font-size: 16px;
      }
    }
    p {
      color: $string-color;
      font-size: 14px;
      padding-top: 0px;
      margin-bottom: 0px !important;
    }
  }
  .various {
    span,
    p {
      margin-bottom: 0px !important;
      padding-left: 0px;
      width: fit-content;
    }
  }
  &-info {
    color: $text-color;
    font-size: 14px;
    margin: 5px 0;
    width: 100%;
  }
  &-info-box {
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 15px;
    color: white;
    // padding: 10px 12px 8px 12px;
    padding: 5px 7px 3px 7px;
    font-size: 13px;
    width: fit-content;
    line-height: normal;
    word-break: break-word;
    margin-left: 2px;
    margin-right: 2px;
    text-align: center;
    @media (max-width: 1200px) {
      text-align: center;
    }
  }
}
.bottom_space_mobile {
  @media (max-width: 767px) {
    padding-bottom: 10px;
  }
}
.fixed_days {
  background-color: $primary-color;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  padding: 5px 15px;
  position: fixed;
  top: 10px;
  left: calc(50% - 45px);
  transition: all 0.3s;
  visibility: hidden;
  z-index: 999;
}
.show_days {
  transition: all 0.3s;
  visibility: visible;
}
.d-flex-trilha {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.descricao {
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  @media (max-width: 1200px) {
    font-size: 13px;
  }
}
.center_important{
  justify-content: center!important;
}
</style>

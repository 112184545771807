/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Popper = require("popper.js");

window.$ = window.jQuery = require("jquery");

require("admin-lte");
require("bootstrap");

window.Vue = require("vue");

import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

import Swal from "sweetalert2/dist/sweetalert2.js";

const Toast = Swal.mixin({
  toast: true,
  position: "top",
  timer: 3000,
  showConfirmButton: false
});
window.Toast = Toast;

import money from "v-money";
Vue.use(money, {
  precision: 4
});

import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d);



// import vClickOutside from "v-click-outside";
import Axios from "axios";
import {
  type
} from "jquery";
;

Vue.component("the-signup", require("./front/TheSignUpForm.vue").default);
Vue.component('the-password', require('./front/TheRemakePassword.vue').default)
Vue.component('the-new-programation', require('./front/TheNewProgramation.vue').default)

// Vue.component(
//   "the-city-selector",
//   require("./cms/UISelectCityAndState.vue").default
// );

const app = new Vue({
  el: "#app",
});

$(".cashback-popup").modal();
